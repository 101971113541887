import { axiosInstance, currentAPI } from '../../config';
import handleApiError from '../handleApiError';

import { LOADING, STOP_LOADING } from './slice';

export const getResults = (session, courseId) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(
            `${currentAPI}result/:campusId/get_all_results/${session}/${courseId}`,
        );
        if (response.status === 200) {
            const { data } = response?.data || {};
            return data;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const setScoreDistribution = (session, courseId, payload) => async (dispatch) => {
    // dispatch(LOADING());

    try {
        const response = await axiosInstance.post(
            `${currentAPI}result/:campusId/create_score_distribution/${session}/${courseId}`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const editScoreDistribution = (distributionId, payload) => async (dispatch) => {
    // dispatch(LOADING());

    try {
        const response = await axiosInstance.put(
            `${currentAPI}result/:campusId/edit_score_distribution/${distributionId}`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getScoreDistribution = (session, courseId) => async (dispatch) => {
    dispatch(LOADING());

    try {
        const response = await axiosInstance.get(
            `${currentAPI}result/:campusId/get_score_distribution_by_course_and_session/${session}/${courseId}`,
        );
        if (response.status === 200) {
            const { data } = response?.data || {};
            return data;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getCaBroadsheet = (session, courseId) => async (dispatch) => {
    dispatch(LOADING());

    try {
        const response = await axiosInstance.get(
            `${currentAPI}result/:campusId/course_ca_broadsheet/${session}/${courseId}`,
        );
        if (response.status === 200) {
            const { data } = response?.data || {};
            return data;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getExamBroadsheet = (session, courseId) => async (dispatch) => {
    dispatch(LOADING());

    try {
        const response = await axiosInstance.get(
            `${currentAPI}result/:campusId/course_exam_broadsheet/${session}/${courseId}`,
        );
        if (response.status === 200) {
            const { data } = response?.data || {};
            return data;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const recordStudentScore =
    ({ session, courseLevel, studentId, courseId, finalPayload }) =>
    async (dispatch) => {
        dispatch(LOADING());

        try {
            const response = await axiosInstance.put(
                `${currentAPI}result/:campusId/record_student_score/${studentId}/${session}/${courseLevel}/${courseId}`,
                finalPayload,
            );
            if (response.status === 200) {
                return true;
            }
        } catch (error) {
            handleApiError(error);
        } finally {
            dispatch(STOP_LOADING());
        }
    };
