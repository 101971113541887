import { axiosInstance, currentAPI } from '../../config';
import handleApiError from '../handleApiError';

import { LOADING, STOP_LOADING, SAVED_ACADEMIC_STRUCTURE } from './slice';

export const getSavedAcademicStructure = () => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}structure/:campusId/valid_structure`);
        if (response.status === 200) {
            const { data } = response?.data || {};
            dispatch(SAVED_ACADEMIC_STRUCTURE(data));
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};
