import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';
import { encodeQuery } from '../../utils';
import handleApiError from '../handleApiError';

import { GET_ASSIGNED_COURSES, GET_RESOURCE_FILES, GET_RESOURCES_FOLDERS, LOADING, STOP_LOADING } from './slice';

export const getAssignedCourses = (session) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}resources/:campusId/get-assigned-courses/${session}`);
        if (response.status === 200) {
            const { data } = response?.data || {};
            const formattedData = data?.map((item) => {
                return {
                    numberOfStudent: item?.numberOfStudent,
                    ...item.course,
                };
            });
            dispatch(GET_ASSIGNED_COURSES(formattedData));
        }
    } catch (error) {
        dispatch(GET_ASSIGNED_COURSES([]));
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const uploadFile = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.patch(`${currentAPI}resources/:campusId/folders/upload/file`, payload);
        if (response.status === 201 || response.status === 200) {
            toast.success('File uploaded successfully');
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getResourceFiles = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}resources/:campusId/folders/file/upload`);
        if (response.status === 201 || response.status === 200) {
            const modifiedData = response.data.data?.map((item) => {
                return {
                    ...item,
                    fileId: item?._id,
                    dateCreated: item?.createdAt,
                    ...item?.content?.[0],
                };
            });
            dispatch(GET_RESOURCE_FILES(modifiedData));
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createFolder = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}resources/:campusId/folders`, payload);
        if (response.status === 201) {
            toast.success('Folder created successfully');
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getResourceFolders = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}resources/:campusId/folders`);
        if (response.status === 200) {
            dispatch(GET_RESOURCES_FOLDERS(response.data.data));
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const shareResourceFile = (payload, fileId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.patch(
            `${currentAPI}resources/:campusId/folders/sharing/${fileId}/file`,
            payload,
        );
        if (response.status === 200 || response.status === 201) {
            toast.success('Resource shared successfully');
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const shareResourceFolder = (payload, folderId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.patch(
            `${currentAPI}resources/:campusId/folders/sharing/${folderId}/folder`,
            payload,
        );
        if (response.status === 200 || response.status === 201) {
            toast.success('Resource shared successfully');
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const moveFileToFolder = (payload, fileId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.patch(
            `${currentAPI}resources/:campusId/folders/file/${fileId}/move`,
            payload,
        );
        if (response.status === 200 || response.status === 201) {
            toast.success('Resource moved successfully');
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const searchResourceFolder = (query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const queries = encodeQuery({ ...query });
        const response = await axiosInstance.get(`${currentAPI}resources/:campusId/folders/search?${queries}`);
        if (response.status === 200 || response.status === 201) {
            dispatch(GET_RESOURCES_FOLDERS(response.data.data));
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const renameResourceFile = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.patch(`${currentAPI}resources/:campusId/folders/rename/file`, payload);
        if (response.status === 200 || response.status === 201) {
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getNestedFolders = (folderId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}resources/:campusId/folders/${folderId}/inside`);
        if (response.status === 200 || response.status === 201) {
            dispatch(GET_RESOURCES_FOLDERS(response.data.data));
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getNestedFiles = (folderId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}resources/:campusId/folders/${folderId}/file`);
        if (response.status === 200 || response.status === 201) {
            const modifiedData = response.data.data?.map((item) => {
                return {
                    ...item,
                    fileId: item?._id,
                    dateCreated: item?.createdAt,
                    ...item?.content?.[0],
                };
            });
            dispatch(GET_RESOURCE_FILES(modifiedData));
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const moveFolderToFolder = (payload, folderId) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.patch(
            `${currentAPI}resources/:campusId/folders/${folderId}/move`,
            payload,
        );
        if (response.status === 200 || response.status === 201) {
            toast.success('Resource moved successfully');
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const renameResourceFolder = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.patch(`${currentAPI}resources/:campusId/folders/rename`, payload);
        if (response.status === 200 || response.status === 201) {
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deleteResourceFolder = (payload) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await fetch(`${currentAPI}resources/:campusId/folders/delete/folder`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token'))}`,
            },
            body: JSON.stringify(payload),
        });
        if (response.status === 200) {
            const successMessage = response?.data?.message || 'Folder Deleted Successfully!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const deleteResourceFile = (payload) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await fetch(`${currentAPI}resources/:campusId/folders/delete/file`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('token'))}`,
            },
            body: JSON.stringify(payload),
        });
        if (response.status === 200) {
            const successMessage = response?.data?.message || 'File Deleted Successfully!';
            toast.success(successMessage);
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};
