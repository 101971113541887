import { axiosInstance, currentAPI } from '../../config';
import handleApiError from '../handleApiError';

import { GET_ADMISSIONS_SESSIONS, LOADING, STOP_LOADING, GET_ACTIVE_SEMESTER, GET_ACTIVE_SESSION } from './slice';

export const getAdmissionSessions = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}admission/:campusId/all-session`);
        if (response.status === 200) {
            dispatch(GET_ADMISSIONS_SESSIONS(response.data.data));
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getActiveSession = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}admission/:campusId/active-session`);
        if (response.status === 200) {
            const { data } = response.data;
            dispatch(GET_ACTIVE_SESSION(data?.admissionYear || ''));
            return data;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getActiveSemester = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}admission/:campusId/active-semester`);
        if (response.status === 200) {
            const { data } = response.data;
            dispatch(GET_ACTIVE_SEMESTER(data?.semester || ''));
            return data;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};
