import { axiosInstance, currentAPI } from '../../config';
import handleApiError from '../handleApiError';

import {
    GET_EXAM_TIMETABLE,
    GET_LECTURE_TIMETABLE,
    LOADING,
    STOP_LOADING,
    SAVE_FRIDAY_TIMETABLE,
    SAVE_MONDAY_TIMETABLE,
    SAVE_TUESDAY_TIMETABLE,
    SAVE_WEDNESDAY_TIMETABLE,
    SAVE_THURSDAY_TIMETABLE,
    SAVE_NEXT_ACTIVITY,
} from './slice';

export const getLectureTimetable = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}timetable/:campusId/lecture`);
        if (response.status === 200) {
            dispatch(GET_LECTURE_TIMETABLE(response.data.data));
            const { data } = response.data;
            const mondayTimetable = await data.filter((timetable) => timetable.weekday.toLowerCase() === 'monday');
            if (mondayTimetable) {
                dispatch(SAVE_MONDAY_TIMETABLE(mondayTimetable));
            }
            const tuesdayTimetable = await data.filter((timetable) => timetable.weekday.toLowerCase() === 'tuesday');
            if (tuesdayTimetable) {
                dispatch(SAVE_TUESDAY_TIMETABLE(tuesdayTimetable));
            }
            const wednesdayTimetable = await data.filter(
                (timetable) => timetable.weekday.toLowerCase() === 'wednesday',
            );
            if (wednesdayTimetable) {
                dispatch(SAVE_WEDNESDAY_TIMETABLE(wednesdayTimetable));
            }
            const thursdayTimetable = await data.filter((timetable) => timetable.weekday.toLowerCase() === 'thursday');
            if (thursdayTimetable) {
                dispatch(SAVE_THURSDAY_TIMETABLE(thursdayTimetable));
            }
            const fridayTimetable = await data.filter((timetable) => timetable.weekday.toLowerCase() === 'friday');
            if (fridayTimetable) {
                dispatch(SAVE_FRIDAY_TIMETABLE(fridayTimetable));
            }

            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getExamTimetable = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}timetable/:campusId/exam`);
        if (response.status === 200) {
            dispatch(GET_EXAM_TIMETABLE(response.data.data));
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const getNextActivities = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}timetable/:campusId/next-activity`);
        if (response.status === 200) {
            // const nextActivities = response.data.data?.map((item) => ({
            //     ...item.activity,
            //     ...item.from,
            //     ...item.to,
            // }));
            dispatch(SAVE_NEXT_ACTIVITY(response.data.data));
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};
