import { axiosInstance, currentAPI } from '../../config';
import handleApiError from '../handleApiError';

import { GET_CAMPUSES, LOADING, STOP_LOADING } from './slice';

export const getAllCampus = () => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}staff/campus`);
        if (response.status === 200) {
            const { data } = response?.data || {};
            dispatch(GET_CAMPUSES(data));
            return data;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};
