import { useEffect, useState } from 'react';

import { useDispatch } from 'react-redux';
import { Navigate, Routes, Route, useNavigate } from 'react-router-dom';

import ConfirmActionDialogue from './components/popups/confirm-action';
import { setUnauthorizedHandler } from './config/axios';
import Layout from './layout';
import { logoutUser } from './redux/user/actions';
import { routes } from './routes';
import { parseJSON } from './utils/useJSON';
import 'react-datepicker/dist/react-datepicker.css';

function App() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = parseJSON('token', '');

    const isAuthenticated = token ? true : false;
    const [showTimeoutModal, setshowTimeoutModal] = useState(false);

    useEffect(() => {
        setUnauthorizedHandler(() => setshowTimeoutModal(true));
    }, []);

    return (
        <>
            <Routes>
                {routes.map((route) => (
                    <Route
                        key={route.path}
                        path={isAuthenticated ? '/' : route.path}
                        element={isAuthenticated ? <Navigate to="/dashboard" replace /> : route.element}
                    />
                ))}

                <Route path="*" element={<Layout />} />
            </Routes>

            <ConfirmActionDialogue
                show={showTimeoutModal}
                withIcon={false}
                withClose={false}
                closeOnBackdrop={false}
                withCancelBtn={false}
                bgColor="#6366F1"
                title="You have been signed out!"
                subtitle="You have been signed out of the platform. Sessions in all tabs have been signed out."
                confirmAction={() => dispatch(logoutUser(navigate))}
                btn2Text="Sign in again"
            />
        </>
    );
}

export default App;
